import React from 'react'

function DeliverySection() {
  return (
    <div>
      DeliverySection
    </div>
  )
}

export default DeliverySection

import React from 'react'

function AssignWaiter({ orderInfo, setOrderInfo }) {
  return (
    <div>
      AssignWaiter
    </div>
  )
}

export default AssignWaiter

import React from 'react'

function PickupSection() {
  return (
    <div>
      PickupSection
    </div>
  )
}

export default PickupSection
